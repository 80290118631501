import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import styled from 'styled-components'
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Does iOS Developer Do?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "An iOS developer develops applications for mobile devices that use Apple iOS operating system. A good developer is excellent at Objective-C or Swift programming languages for the Apple iOS platform."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What iOS Developer Level can I Hire?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "iOS development is not a child’s play. You should hire experienced iOS Developers. Codestaff provides you with the best and most experienced iOS developers in the industry, so you don’t have to worry about anything."
        }
    }
}

const productMeta = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "Apple ios Developer",
    "image": "https://codestaff.io/ios-developer.png",
    "description": "Apple ios Developers at Code Staff have all of the skill necessary to help your mobile app development. Hire a Remote ios Developer and have them join your team.",
    "brand": "Code Staff",
    "offers": {
        "@type": "Offer",
        "url": "https://codestaff.io/ios-developer",
        "priceCurrency": "USD",
        "price": "99",
        "priceValidUntil": "2020-05-27",
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "1",
        "reviewCount": "1"
    },
    "review": {
        "@type": "Review",
        "name": "Roll Digital",
        "reviewBody": "I couldn't be happier!",
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1"
        },
        "datePublished": "2020-05-06",
        "author": { "@type": "Person", "name": "Roll Digital" },
        "publisher": { "@type": "Organization", "name": "Freelancer Website" }
    }
}

const MainWrapper = styled.div`
margin: 0;
`

const IosDeveloper = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> iOS Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top iOS Developers. Top
                                    companies and start-ups choose Codestaff professional iOS
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top iOS Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE IOS DEVELOPERS' banner='/ios-developer.png' bannerAlt='ios developer banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default IosDeveloper