import React from 'react'
import Layout from '../components/layout'
import IosDeveloper from '../components/categories/ios-developer'
import Seo from '../components/seo'

const IosDeveloperPage = ({ location }) => {
  return (
    <Layout location={location}>
      <IosDeveloper />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top iOS Developer Freelancers | Codestaff'
  const description =
    'Hire the best iOS Developer freelancers at Codestaff. Get the top 1% of iOS Developer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default IosDeveloperPage
